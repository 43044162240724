<template>
    <div id="tickets">
        <div class="description">{{ $t('public.form.tickets') }}</div>
        <div class="tickets-container">
            <div class="ticket" v-for="(ticket, index) in tickets" :key="index">
                <div class="left">
                    <div class="element">
                        <div class="label">{{ $t('public.form.ticket_number') }}:</div>
                        <div class="value">{{ ticket.number }}</div>
                    </div>
                    <div class="element">
                        <div class="label">{{ $t('public.form.ticket_date') }}:</div>
                        <div class="value">{{ ticket.date }}</div>
                    </div>
                    <div class="element">
                        <div class="label">{{ $t('public.form.ticket_price') }}:</div>
                        <div class="value">{{ ticket.price }}</div>
                    </div>
                </div>
                <div class="right">
                    <div v-if="step != 4" class="button" @click="confirmRemoveTicket(index)"></div>
                </div>
            </div>
            <Modal v-if="showModal" :type="'ticket'" :ticketNumber="ticketNumber" v-on:close="closePopup()" v-on:confirm="removeTicket()"></Modal>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/ui/Modal.vue'

export default {
    name: 'Tickets',
    components: {
        Modal
    },
    computed: {
        tickets: {
            get: function () {
                return this.$store.getters['form/getTickets']
            },
            set: function (value) {
                this.$store.commit('form/setTickets', value)
            }
        },
        ticketsIds: {
            get: function () {
                return this.$store.getters['form/getTicketsIds']
            },
            set: function (value) {
                this.$store.commit('form/setTicketsIds', value)
            }
        },
        step: function () {
            return this.$store.getters['form/getStep']
        }
    },
    data() {
        return {
            showModal: false,
            ticketNumber: -1,
            index: -1
        }
    },
    methods: {
        confirmRemoveTicket(index) {
            this.showModal = true
            this.ticketNumber = this.tickets[index].number
            this.index = index
        },
        removeTicket() {
            this.closePopup()

            this.tickets.splice(this.index, 1)
            this.ticketsIds.splice(this.index, 1)

            if (this.tickets.length == 0) {
                this.$store.commit('form/setTicketNumber', '')
                this.$store.commit('form/setTicketDate', '')
                this.$store.commit('form/setTicketPrice', '')
                this.$store.commit('form/setStep', 2)
            }
        },
        closePopup() {
            this.showModal = false
            this.ticketNumber = -1
            this.index = -1
        }
    }
}
</script>

<style lang="scss">
#tickets {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e6e6;
    max-width: 1600px;

    .description {
        width: 100%;
        font-size: 18px;
        font-family: Speedee-bd;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
        margin: 28px 0 20px 0;
    }

    .tickets-container {
        width: 100%;

        .ticket {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: left;
            margin-bottom: 20px;

            .left {
                width: 80%;

                .element {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: left;
                    width: 100%;
                    border-left: 3px solid #ddd;
                    padding-left: 16px;

                    .label {
                        font-size: 16px;
                        color: #222;
                        margin-right: 5px;
                    }

                    .value {
                        font-family: Speedee-bd;
                        font-size: 16px;
                        color: $green;
                        text-decoration: underline;
                    }
                }
            }

            .right {
                width: 20%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                .button {
                    width: 20px;
                    height: 20px;
                    background-image: url('../../../public/img/remove.png');
                    background-size: 20px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    cursor: pointer;
                }
            }
        }
    }

    @media screen and (max-width: 720px) {
        padding: 20px;
    }
}
</style>
