<template>
    <div id="step4">
        <div class="row">
            <div class="img"></div>
        </div>
        <div class="row">
            <div class="title">{{ $t('public.form.step4_title') }}</div>
        </div>
        <div class="row">
            <div class="step4-description">
                <div class="section section1">{{ $t('public.form.step4_description1') }}</div>
                <div class="section section2">{{ email }}</div>
                <div class="section section3">{{ $t('public.form.setp4_description2') }}</div>
            </div>
        </div>
        <CustomerInfo></CustomerInfo>
        <Tickets></Tickets>
        <div class="row">
            <div class="button" @click="newTicket()" v-html="$t('public.form.new_ticket')"></div>
        </div>
    </div>
</template>

<script>
import CustomerInfo from '@/components/ui/CustomerInfo'
import Tickets from '@/components/ui/Tickets'

export default {
    name: 'Step4',
    components: {
        CustomerInfo,
        Tickets
    },
    computed: {
        email: function () {
            return this.$store.getters['form/getEmail']
        }
    },
    methods: {
        newTicket: function () {
            this.$store.commit('form/setTicketNumber', '')
            this.$store.commit('form/setTicketDate', '')
            this.$store.commit('form/setTicketPrice', '')
            this.$store.commit('form/setTickets', [])
            this.$store.commit('form/setTicketsIds', [])
            this.$store.commit('form/setStep', 2)
        }
    }
}
</script>

<style lang="scss">
#step4 {
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .img {
            margin: 90px 0 30px 0;
            height: 100px;
            width: 100px;
            background-image: url('../../public/img/plane.png');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .title {
            margin-bottom: 50px;
        }

        .step4-description {
            text-align: left;
            margin-bottom: 50px;
            padding-left: 12px;

            .section {
                font-size: 16px;
                text-align: left;
                display: inline;
            }

            .section2 {
                margin: 0 5px;
                color: $green;
                text-decoration: underline;
            }
        }

        .button {
            width: 300px;
            padding: 20px 25px;
            background-color: $yellow;
            border-radius: 4px;
            color: $black;
            font-size: 24px;
            font-family: Speedee-bd;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 75px auto;
            display: flex;

            &:hover {
                background-color: #ecb116;
            }
        }
    }

    @media screen and (max-width: 720px) {
        .row {
            .img {
                margin-top: 40px;
            }

            .step4-description {
                padding: 20px 20px 20px 30px;
            }
        }
    }
}
</style>
