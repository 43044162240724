<template>
    <div id="step1">
        <div class="title">{{ $t('public.form.title') }}</div>
        <div class="element-container">
            <div class="element">
                <div class="label">{{ $t('public.form.name') }}</div>
                <div class="input">
                    <input type="text" v-model.trim="name" :class="{ error: error.name }" />
                </div>
            </div>
        </div>
        <div class="element-container">
            <div class="element">
                <div class="label">{{ $t('public.form.document_type') }}</div>
                <div class="input">
                    <select v-model="documentType" :class="{ error: error.documentType }">
                        <template v-for="(documentType, index) in documentTypes">
                            <option :value="index" :key="index">{{ documentType }}</option>
                        </template>
                    </select>
                </div>
            </div>
        </div>
        <div class="element-container">
            <div class="element">
                <div class="label">{{ $t('public.form.document') }}</div>
                <div class="input">
                    <input type="text" v-model.trim="document" :class="{ error: error.document }" />
                </div>
            </div>
        </div>
        <div class="element-container">
            <div class="element textarea">
                <div class="label">{{ $t('public.form.fiscal_address') }}</div>
                <div class="input"><textarea v-model.trim="fiscalAddress" :class="{ error: error.fiscalAddress }" /></div>
            </div>
        </div>
        <div class="element-container">
            <div class="element">
                <div class="label">{{ $t('public.form.email') }}</div>
                <div class="input">
                    <input type="text" v-model.trim="email" :class="{ error: error.email }" />
                </div>
                <div class="description">{{ $t('public.form.email_description') }}</div>
            </div>
        </div>
        <div class="element-container">
            <div class="button" @click="changeStep()">{{ $t('public.form.next') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Step1',
    computed: {
        step: {
            get: function () {
                return this.$store.getters['form/getStep']
            },
            set: function (value) {
                this.$store.commit('form/setStep', value)
            }
        },
        name: {
            get: function () {
                return this.$store.getters['form/getName']
            },
            set: function (value) {
                this.$store.commit('form/setName', value)
            }
        },
        documentTypes: {
            get: function () {
                return this.$store.getters['form/getDocumentTypes']
            }
        },
        documentType: {
            get: function () {
                return this.$store.getters['form/getDocumentType']
            },
            set: function (value) {
                this.$store.commit('form/setDocumentType', value)
            }
        },
        document: {
            get: function () {
                return this.$store.getters['form/getDocument']
            },
            set: function (value) {
                this.$store.commit('form/setDocument', value)
            }
        },
        fiscalAddress: {
            get: function () {
                return this.$store.getters['form/getFiscalAddress']
            },
            set: function (value) {
                this.$store.commit('form/setFiscalAddress', value)
            }
        },
        email: {
            get: function () {
                return this.$store.getters['form/getEmail']
            },
            set: function (value) {
                this.$store.commit('form/setEmail', value)
            }
        }
    },
    data() {
        return {
            error: {
                name: false,
                documentType: false,
                document: false,
                fiscalAddress: false,
                email: false
            }
        }
    },
    methods: {
        changeStep: async function () {
            let isValidForm = true

            const isValidName = this.name != ''
            const isValidDocumentType = this.documentType != -1
            const isValidDocument = await this.$store.dispatch('form/validateDocument')
            const isValidFiscalAddress = this.fiscalAddress != ''
            const isValidEmail = await this.$store.dispatch('form/validateEmail')

            if (!isValidName) {
                this.error.name = true
                isValidForm = false
            } else {
                this.error.name = false
            }

            if (!isValidDocumentType) {
                this.error.documentType = true
                isValidForm = false
            } else {
                this.error.documentType = false
            }

            if (!isValidDocument) {
                this.error.document = true
                isValidForm = false
            } else {
                this.error.document = false
            }

            if (!isValidFiscalAddress) {
                this.error.fiscalAddress = true
                isValidForm = false
            } else {
                this.error.fiscalAddress = false
            }

            if (!isValidEmail) {
                this.error.email = true
                isValidForm = false
            } else {
                this.error.email = false
            }

            if (isValidForm) {
                this.$store.commit('form/setClientInformation', true)
                this.$store.commit('form/setStep', 2)
            } else {
                const element = document.getElementById('scrollTo')
                element.scrollIntoView()
            }
        }
    }
}
</script>

<style>
#step1 {
    width: 100%;
}
</style>
