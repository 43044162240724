<template>
    <div id="step2">
        <CustomerInfo></CustomerInfo>
        <Tickets v-if="showTickets"></Tickets>
        <div class="title">{{ $t('public.form.ticket_title') }}</div>
        <div class="element-container">
            <div class="element">
                <div class="label">
                    <Modal v-if="tooltipNumber" :type="'number'" v-on:close="tooltipNumber = false"></Modal>
                    <div class="tooltip" @click="tooltipNumber = !tooltipNumber"></div>
                    <div class="text">{{ $t('public.form.ticket_number') }}</div>
                </div>
                <div class="input">
                    <input type="text" v-model.trim="ticketNumber" :class="{ error: error.ticketNumber }" />
                </div>
            </div>
        </div>
        <div class="element-container">
            <div class="element">
                <div class="label">
                    <Modal v-if="tooltipDate" :type="'date'" v-on:close="tooltipDate = false"></Modal>
                    <div class="tooltip" @click="tooltipDate = !tooltipDate"></div>
                    <div class="text">{{ $t('public.form.ticket_date') }}</div>
                </div>
                <div class="input">
                    <input type="date" v-model="ticketDate" :min="minDate" :max="maxDate" :class="{ error: error.ticketDate }" />
                </div>
            </div>
        </div>
        <div class="element-container">
            <div class="element">
                <div class="label">
                    <Modal v-if="tooltipPrice" :type="'price'" v-on:close="tooltipPrice = false"></Modal>
                    <div class="tooltip" @click="tooltipPrice = !tooltipPrice"></div>
                    <div class="text">{{ $t('public.form.ticket_price') }}</div>
                </div>
                <div class="input">
                    <input type="number" v-model="ticketPrice" @change="updateTicketPrice()" :class="{ error: error.ticketPrice }" />
                </div>
            </div>
        </div>
        <div v-if="error.invalidTicket" class="element-container">
            <Message type="error" :msg="errorMessage"></Message>
        </div>
        <div class="element-container">
            <div class="button" @click="addTicket()">{{ $t('public.form.ticket_add') }}</div>
            <div v-if="showTickets" class="cancel" @click="cancel">{{ $t('public.form.cancel') }}</div>
        </div>
    </div>
</template>

<script>
import CustomerInfo from '@/components/ui/CustomerInfo'
import Tickets from '@/components/ui/Tickets'
import Modal from '@/components/ui/Modal.vue'
import Message from '@/components/ui/Message.vue'

export default {
    name: 'Step2',
    components: {
        CustomerInfo,
        Tickets,
        Modal,
        Message
    },
    computed: {
        step: {
            get: function () {
                return this.$store.getters['form/getStep']
            },
            set: function (value) {
                this.$store.commit('form/setStep', value)
            }
        },
        ticketNumber: {
            get: function () {
                return this.$store.getters['form/getTicketNumber']
            },
            set: function (value) {
                this.$store.commit('form/setTicketNumber', value)
            }
        },
        ticketDate: {
            get: function () {
                return this.$store.getters['form/getTicketDate']
            },
            set: function (value) {
                this.$store.commit('form/setTicketDate', value)
            }
        },
        ticketPrice: {
            get: function () {
                return this.$store.getters['form/getTicketPrice']
            },
            set: function (value) {
                this.$store.commit('form/setTicketPrice', value)
            }
        },
        showTickets: function () {
            return this.$store.getters['form/getTickets'].length > 0
        },
        maxDate: function () {
            return this.moment().format('YYYY-MM-DD')
        },
        minDate: function () {
            return this.moment().subtract(4, 'years').format('YYYY-MM-DD')
        }
    },
    data() {
        return {
            tooltipNumber: false,
            tooltipDate: false,
            tooltipPrice: false,
            error: {
                ticketNumber: false,
                ticketDate: false,
                ticketPrice: false,
                invalidTicket: false
            },
            errorMessage: ''
        }
    },
    methods: {
        addTicket: async function () {
            let isValidForm = true

            const isValidNumber = this.ticketNumber != ''
            const isValidDate = this.ticketDate != '' && this.moment(this.ticketDate).isValid()
            const isValidPrice = this.ticketPrice != ''
            const tickets = this.$store.getters['form/getTickets']

            if (!isValidNumber) {
                this.error.ticketNumber = true
                isValidForm = false
            } else if (this.ticketNumber.length != 20) {
                this.error.ticketNumber = true
                this.error.invalidTicket = true
                this.errorMessage = 'public.errors.format_ticket'
                isValidForm = false
            } else {
                this.error.ticketNumber = false
                this.error.invalidTicket = false
                this.errorMessage = ''
            }

            if (!isValidDate) {
                this.error.ticketDate = true
                isValidForm = false
            } else {
                this.error.ticketDate = false
            }

            if (!isValidPrice) {
                this.error.ticketPrice = true
                isValidForm = false
            } else {
                this.error.ticketPrice = false
            }

            for (const ticket of tickets) {
                if (ticket.number == this.ticketNumber) {
                    this.error.ticketNumber = true
                    this.error.invalidTicket = true
                    this.errorMessage = 'public.errors.duplicated_ticket'
                    isValidForm = false
                }
            }

            if (isValidForm) {
                const ticket = {
                    number: this.ticketNumber,
                    date: this.ticketDate,
                    price: parseFloat(this.ticketPrice).toFixed(2)
                }

                const self = this
                this.$overlay.show()

                this.$store
                    .dispatch('form/validateTicket', {
                        invoice_name: this.$store.getters['form/getName'],
                        document_type: this.$store.getters['form/getDocumentType'],
                        customer_cif: this.$store.getters['form/getDocument'],
                        fiscal_address: this.$store.getters['form/getFiscalAddress'],
                        email: this.$store.getters['form/getEmail'],
                        ticket: ticket.number,
                        date: ticket.date,
                        import: ticket.price
                    })
                    .then(function (result) {
                        self.$overlay.hide()

                        if (!result.status) {
                            self.error.invalidTicket = true

                            if (result.error.msg == 'public.errors.used_ticket') {
                                result.error.msg = self.$t(result.error.msg, { ticket: self.ticketNumber })
                            }

                            self.errorMessage = result.error.msg
                        } else {
                            self.error.invalidTicket = false
                            self.errorMessage = ''
                            self.$store.commit('form/addTicket', ticket)
                            self.step = 3
                        }
                    })
                    .catch(function () {
                        self.$overlay.hide()

                        self.errorMessage = self.$t('public.errors.api')
                    })
            } else {
                const element = document.getElementById('scrollTo')
                element.scrollIntoView()
            }
        },
        updateTicketPrice: function () {
            this.ticketPrice = parseFloat(this.ticketPrice).toFixed(2)
        },
        cancel: function () {
            this.ticketNumber = ''
            this.ticketDate = ''
            this.ticketPrice = ''
            this.step = 3
        }
    }
}
</script>

<style lang="scss">
#step2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .title {
        margin-top: 110px;
    }

    .element-container {
        .element {
            .label {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: left;
                .tooltip {
                    width: 20px;
                    height: 20px;
                    background-image: url('../../public/img/icon_help.png');
                    background-size: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    display: inline-block;
                    margin-right: 10px;
                    position: relative;
                    top: 5px;
                }
                .text {
                    width: calc(100% - 30px);
                    display: inline-block;
                }
            }
        }

        .button {
            margin-bottom: 40px !important;
        }

        .cancel {
            margin-bottom: 20px;
            color: $green;
            font-family: Speedee-bd;
            font-size: 20px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 720px) {
        .title {
            margin-top: 50px;
        }
    }
}
</style>
