<template>
    <div id="customer-info">
        <div class="left">
            <div class="element">
                <div class="label">{{ $t('public.form.name') }}:</div>
                <div class="value">{{ name }}</div>
            </div>
            <div class="element">
                <div class="label">{{ $t('public.form.document') }}:</div>
                <div class="value">{{ documentTypes[documentType] }} - {{ document }}</div>
            </div>
            <div class="element fiscal_address">
                <div class="label">{{ $t('public.form.fiscal_address') }}:</div>
                <div class="value">{{ fiscalAddress }}</div>
            </div>
            <div class="element">
                <div class="label">{{ $t('public.form.email') }}:</div>
                <div class="value">{{ email }}</div>
            </div>
        </div>
        <div class="right">
            <div v-if="step != 4" class="button" @click="changeCustomerInfo()"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerInfo',
    computed: {
        step: {
            get: function () {
                return this.$store.getters['form/getStep']
            },
            set: function (value) {
                this.$store.commit('form/setStep', value)
            }
        },
        name: {
            get: function () {
                return this.$store.getters['form/getName']
            }
        },
        documentTypes: {
            get: function () {
                return this.$store.getters['form/getDocumentTypes']
            }
        },
        documentType: {
            get: function () {
                return this.$store.getters['form/getDocumentType']
            }
        },
        document: {
            get: function () {
                return this.$store.getters['form/getDocument']
            }
        },
        fiscalAddress: {
            get: function () {
                return this.$store.getters['form/getFiscalAddress']
            }
        },
        email: {
            get: function () {
                return this.$store.getters['form/getEmail']
            }
        }
    },
    methods: {
        changeCustomerInfo() {
            this.$store.commit('form/setTicketNumber', '')
            this.$store.commit('form/setTicketDate', '')
            this.$store.commit('form/setTicketPrice', '')
            this.$store.commit('form/setStep', 1)
        }
    }
}
</script>

<style lang="scss">
#customer-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e6e6;
    max-width: 1600px;

    .left {
        width: 80%;
        .element {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: left;
            .label {
                font-size: 16px;
                color: #222;
                margin-right: 5px;
            }

            .value {
                font-family: Speedee-bd;
                font-size: 16px;
                color: $green;
                text-decoration: underline;
                text-align: left;
            }

            &.fiscal_address {
                display: block;
                width: 100%;
                text-align: left;

                .label,
                .value {
                    display: inline;
                }
            }
        }
    }

    .right {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        .button {
            width: 20px;
            height: 20px;
            background-image: url('../../../public/img/arrowRight.png');
            background-size: 20px;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 720px) {
        padding: 20px;
    }
}
</style>
