<template>
    <div id="step3">
        <CustomerInfo></CustomerInfo>
        <Tickets></Tickets>
        <div class="element">
            <div class="addTicket" @click="addTicket()">{{ $t('public.form.ticket_new') }}</div>
        </div>
        <!-- <div class="element" id="legal">
            <input type="checkbox" id="legalCheckbox" v-model="checkbox" />
            <label id="legal_label" for="legal" :class="{ error: error.checkbox }">
                <div @click="checkbox = !checkbox">
                    {{ $t('public.form.legal') }}
                    <div id="legal_link" @click="openLegalLink">{{ $t('public.form.legal_link') }}</div>
                    *
                </div>
            </label>
        </div> -->
        <div class="element" id="legal2">
            <div>{{ $t('public.form.legal1') }}</div>
            <div id="legal_link" @click="openLegalLink">{{ $t('public.form.legal_link') }}</div>
            <div>{{ $t('public.form.legal2') }}</div>
        </div>
        <div class="element">
            <div class="captcha" :class="{ error: error.recaptcha }">
                <VueRecaptcha :sitekey="captchaKey" @verify="recaptcha = true" :loadRecaptchaScript="true"></VueRecaptcha>
            </div>
        </div>
        <div class="element" v-if="errorMessage != ''">
            <Message type="error" :msg="errorMessage"></Message>
        </div>
        <div class="element">
            <div class="button" @click="requestTicket()" v-html="$t('public.form.request_ticket')"></div>
        </div>
    </div>
</template>

<script>
import CustomerInfo from '@/components/ui/CustomerInfo'
import Tickets from '@/components/ui/Tickets'
import VueRecaptcha from 'vue-recaptcha'
import Message from '@/components/ui/Message.vue'

export default {
    name: 'step3',
    components: {
        CustomerInfo,
        Tickets,
        VueRecaptcha,
        Message
    },
    computed: {
        step: {
            get: function () {
                return this.$store.getters['form/getStep']
            },
            set: function (value) {
                this.$store.commit('form/setStep', value)
            }
        },
        captchaKey: {
            get: function () {
                return this.$store.getters['form/getCaptchaKey']
            }
        }
    },
    data() {
        return {
            // checkbox: false,
            recaptcha: false,
            error: {
                // checkbox: false,
                recaptcha: false
            },
            errorMessage: ''
        }
    },
    methods: {
        addTicket() {
            this.$store.commit('form/setTicketNumber', '')
            this.$store.commit('form/setTicketDate', '')
            this.$store.commit('form/setTicketPrice', '')
            this.$store.commit('form/setStep', 2)
        },
        openLegalLink(event) {
            event.preventDefault()
            event.stopPropagation()
            const url = `${process.env.VUE_APP_API_URL}static/files/politica-privacidad.pdf`
            window.open(url)
            // window.open(this.$t('public.links.privacy_policy_link'))
        },
        requestTicket() {
            let isValidForm = true

            // if (!this.checkbox) {
            //     this.error.checkbox = true
            //     isValidForm = false
            // } else {
            //     this.error.checkbox = false
            // }

            if (!this.recaptcha) {
                this.error.recaptcha = true
                isValidForm = false
            } else {
                this.error.recaptcha = false
            }

            if (isValidForm) {
                const self = this
                this.$overlay.show()
                this.$store
                    .dispatch('form/addInvoice')
                    .then((response) => {
                        if (!response.status) {
                            if (response.error.msg == 'public.errors.used_ticket') {
                                response.error.msg = self.$t(response.error.msg, { ticket: response.error.ticket_number })
                            }
                            self.errorMessage = response.error.msg
                        } else {
                            self.errorMessage = ''
                            self.step = 4
                        }
                        self.$overlay.hide()
                    })
                    .catch(() => {
                        self.errorMessage = self.$t('public.errors.api')
                        self.$overlay.hide()
                    })
            } else {
                const element = document.getElementById('scrollTo')
                element.scrollIntoView()
            }
        }
    }
}
</script>

<style lang="scss">
#step3 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .addTicket {
        margin: 120px 0 56px 0;
        color: $green;
        font-family: Speedee-bd;
        font-size: 20px;
        text-decoration: underline;
        cursor: pointer;
    }

    .element {
        width: 100%;
        display: block;

        &#legal2 {
            display: flex;
            justify-content: center;
        }

        .button {
            width: 300px;
            padding: 20px 25px;
            background-color: $yellow;
            border-radius: 4px;
            color: $black;
            font-size: 24px;
            font-family: Speedee-bd;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 75px auto;
            display: flex;

            &:hover {
                background-color: #ecb116;
            }
        }

        #legalCheckbox {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-right: 20px;
            float: left;
        }

        #legal_label {
            font-size: 14px;
            cursor: pointer;
            display: inline;
            text-align: left;
            &.error {
                color: $red;
                font-family: Speedee-bd;
            }
        }

        #legal_link {
            color: $green;
            text-decoration: underline;
            margin: 0 5px;
            display: inline;
            cursor: pointer;
        }

        .captcha {
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.error {
                iframe {
                    border: 2px solid $red;
                }
            }
        }
    }

    @media screen and (max-width: 720px) {
        #legal {
            padding: 0 20px;
        }

        .addTicket {
            margin: 50px 0;
        }
    }
}
</style>
