<template>
    <div class="form-container">
        <main id="main">
            <div class="information" v-if="!isClientInformation">
                <div class="text">{{ $t('public.description') }}</div>
            </div>
            <div class="form-body">
                <div class="form" id="scrollTo">
                    <div v-if="step != 4" class="process">
                        <div class="ball"></div>
                        <div class="line" :class="{ disabled: step == 1 }"></div>
                        <div class="ball" :class="{ disabled: step == 1 }"></div>
                    </div>
                    <div class="content">
                        <Step1 v-if="step == 1"></Step1>
                        <Step2 v-if="step == 2"></Step2>
                        <Step3 v-if="step == 3"></Step3>
                        <Step4 v-if="step == 4"></Step4>
                    </div>
                </div>
            </div>
            <div class="separator"></div>
        </main>
    </div>
</template>

<script>
import Step1 from '@/components/Step1'
import Step2 from '@/components/Step2'
import Step3 from '@/components/Step3'
import Step4 from '@/components/Step4'

export default {
    name: 'Form',
    components: {
        Step1,
        Step2,
        Step3,
        Step4
    },
    computed: {
        step: {
            get: function () {
                window.scrollTo(0, 0)
                return this.$store.getters['form/getStep']
            },
            set: function (value) {
                this.$store.commit('form/setStep', value)
            }
        },
        isClientInformation: {
            get: function () {
                return this.$store.getters['form/isClientInformation']
            }
        }
    }
}
</script>
<style lang="scss">
.form-container {
    display: flex;
    align-items: center;
    justify-content: center;

    #main {
        width: 100%;

        .information {
            height: 60vh;
            width: 100%;
            background-image: url('../../public/img/header.png');
            background-size: cover;
            background-position: top -100px center;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                width: 800px;
                color: #fff;
                font-family: Speedee-bd;
                font-size: 54px;
                line-height: 68px;
                text-align: center;
                text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
            }
        }

        .form-body {
            background-color: $yellow;
            padding: 60px 55px;

            .form {
                max-width: 1400px;
                margin: 0 auto;
                background: #ffffff;
                box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                width: 100%;
                text-align: center;

                .process {
                    width: 100%;
                    padding: 35px 0 70px 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    .ball {
                        width: 10px;
                        height: 10px;
                        background-color: $yellow;
                        border-radius: 50%;

                        &.disabled {
                            background-color: #e6e6e6;
                        }
                    }

                    .line {
                        width: 272px;
                        height: 4px;
                        background-color: $yellow;

                        &.disabled {
                            background-color: #e6e6e6;
                        }
                    }
                }

                .content {
                    width: 100%;
                    max-width: 600px;
                    overflow: hidden;
                    margin: 0 auto;

                    .title {
                        font-size: 26px;
                        font-family: Speedee-bd;
                        color: $black;
                        margin-bottom: 50px;
                        width: 100%;
                    }

                    .element-container {
                        text-align: center;
                        width: 100%;
                        display: block;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 20px;

                        .element {
                            width: 100%;
                            display: block;
                            align-items: center;
                            justify-content: center;
                            .label {
                                width: 40%;
                                font-size: 16px;
                                color: #000;
                                text-align: left;
                                display: inline-block;
                            }

                            .input {
                                width: 60%;
                                display: inline-block;

                                input {
                                    width: 100%;
                                    font-size: 16px;
                                    color: #000;
                                    border: 1px solid #bebebe;
                                    padding: 15px 20px;
                                    border-radius: 4px;

                                    &.error {
                                        border: 1px solid $red;
                                        background-color: $red2;
                                    }
                                }

                                select {
                                    width: 100%;
                                    font-size: 16px;
                                    color: #000;
                                    border: 1px solid #bebebe;
                                    padding: 15px 20px;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    appearance: none;
                                    background-image: url('../../public/img/arrow.png');
                                    background-size: 12px;
                                    background-position: center right 10px;
                                    background-repeat: no-repeat;

                                    &.error {
                                        border: 1px solid $red;
                                        background-color: $red2;
                                    }
                                }
                            }

                            &.textarea {
                                width: 100%;

                                .label {
                                    margin-bottom: 15px;
                                    width: 100%;
                                }

                                .input {
                                    width: 100%;

                                    textarea {
                                        height: 100px;
                                        width: 100%;
                                        font-size: 16px;
                                        color: #000;
                                        border: 1px solid #bebebe;
                                        resize: none;
                                        padding: 20px;
                                        border-radius: 4px;

                                        &.error {
                                            border: 1px solid $red;
                                            background-color: $red2;
                                        }
                                    }
                                }
                            }

                            .description {
                                font-size: 12px;
                                color: #666;
                                margin: 0;
                                margin-top: 10px;
                                margin-left: 40%;
                                width: 100%;
                                text-align: left;
                                max-width: 360px;
                            }
                        }

                        .button {
                            width: 300px;
                            padding: 20px 25px;
                            background-color: $yellow;
                            border-radius: 4px;
                            color: $black;
                            font-size: 24px;
                            font-family: Speedee-bd;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin: 75px auto;
                            display: flex;

                            &:hover {
                                background-color: #ecb116;
                            }
                        }
                    }
                }
            }
        }

        .separator {
            width: 100%;
            height: 46px;
            background-image: url('../../public/img/separator.png');
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
        }
    }

    @media screen and (max-width: 720px) {
        #main {
            .information {
                padding: 40px;
                height: 100%;
                background-position: center center;

                .text {
                    font-size: 36px;
                    line-height: 36px;
                }
            }

            .form-body {
                padding: 20px;

                .form {
                    .process {
                        .line {
                            width: 90%;
                        }
                        max-width: 250px;
                        margin: 0 auto;
                        padding: 35px 0;
                    }

                    .content {
                        .element-container {
                            padding: 0 25px;
                            .element {
                                .label {
                                    width: 100%;
                                    margin-bottom: 12px;
                                }

                                .input {
                                    width: 100%;
                                }
                                .description {
                                    margin: 4px 0 0 0;
                                    max-width: none;
                                }
                            }

                            .button {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 420px) {
        #main {
            .form-body {
                padding: 20px;

                .form {
                    .content {
                        .title {
                            padding: 0 20px;
                        }

                        .element-container {
                            padding: 0 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
